import { CustomField } from '@/shared/models/CustomFields/CustomField';

import { paths } from '@/shared/services/schema/geppetto-sender-app/customfields.schema';

export type CustomFieldsSchemaResponse =
  paths['/custom-fields/{formType}']['get']['responses']['200']['content']['application/json'];

const mapCustomField = (response: CustomFieldsSchemaResponse['data'][number]) => response as CustomField;

export const mapCustomFieldsSchemaResponseToSchema = (response: CustomFieldsSchemaResponse) => {
  const fields = response.data.map(mapCustomField);
  return fields;
};

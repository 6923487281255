import { ApolloClient, defaultDataIdFromObject, InMemoryCache } from '@apollo/client/core';

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    // Use 'uuid' as the default id field for all types
    if (object.__typename && object.uuid) {
      return `${object.__typename}:${object.uuid}`;
    }
    // Fallback to default behavior if 'uuid' is not present
    return defaultDataIdFromObject(object);
  },
  typePolicies: {
    User: {
      fields: {
        flags: {
          merge: true,
        },
      },
    },
  },
});

export function createApolloClient(baseUrl: string, tenantId: string) {
  return new ApolloClient({
    uri: `${baseUrl}/graphql`,
    cache,
    headers: {
      'Geppetto-Tenant-Id': tenantId,
    },
  });
}

import QuoteWithService, { quoteWithServiceFactory } from './QuoteWithService';
import { AgreedService, Quote, QuoteSet } from '@/shared/models';

interface QuoteSetWithServices {
  id: string;
  createdAt: string; // dateTime
  quotes: QuoteWithService[];
}

export default QuoteSetWithServices;

export const create = ({ id, createdAt, quotes }: QuoteSetWithServices): QuoteSetWithServices => ({
  id,
  createdAt,
  quotes: quotes.map(quoteWithServiceFactory.create),
});

export class AgreedServiceNotFoundError extends Error {
  constructor(q: Quote) {
    super(`Unable to get agreed service ${q.agreedServiceId} for quote ${q.id}`);
  }
}

const createFromQuoteSet = (quoteSet: QuoteSet, services: AgreedService[]): QuoteSetWithServices => ({
  ...quoteSet,
  quotes: quoteSet.quotes.map(quote => {
    const service = services.find(s => s.id === quote.agreedServiceId);
    if (!service) throw new AgreedServiceNotFoundError(quote);
    return quoteWithServiceFactory.createFromQuote(quote, service);
  }),
});

export const quoteSetWithServicesFactory = {
  create,
  createFromQuoteSet,
};

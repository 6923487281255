import Contact, { createContact } from '@/shared/models/Contact';
import DeliveryInstructions, { createDeliveryInstructions } from '@/shared/models/DeliveryInstructions';

interface ContactSuggestion {
  contact: Contact;
  deliveryInstructions: DeliveryInstructions;
}

const newContactSuggestion = (): ContactSuggestion => ({
  contact: createContact(),
  deliveryInstructions: createDeliveryInstructions(),
});

export const createContactSuggestion = (
  { contact, deliveryInstructions }: ContactSuggestion | undefined = newContactSuggestion(),
): ContactSuggestion => ({
  contact,
  deliveryInstructions,
});

export default ContactSuggestion;

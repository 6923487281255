import { AgreedService } from '@/shared/models';

import Quote from './Quote';

interface QuoteWithService extends Quote {
  agreedServiceModel: AgreedService;
}

export default QuoteWithService;

const create = ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  chargesBreakdown,
  createdAt,
  currency,
  eta,
  fees,
  freight,
  net,
  tax,
  quoteSetId,
  total,
  evaluation,
  selectable,
  recommended,
  agreedServiceModel,
}: QuoteWithService): QuoteWithService => ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  chargesBreakdown,
  createdAt,
  currency,
  eta,
  fees,
  freight,
  net,
  tax,
  quoteSetId,
  total,
  evaluation,
  selectable,
  recommended,
  agreedServiceModel,
});

const createFromQuote = (quote: Quote, agreedService: AgreedService): QuoteWithService => {
  return create({
    ...quote,
    agreedServiceModel: agreedService,
  });
};

export const quoteWithServiceFactory = {
  create,
  createFromQuote,
};

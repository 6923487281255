import { Temporal } from '@js-temporal/polyfill';

import { TimeRange } from '@/shared/DateTime';
import DATETIME_LOCALE_FORMATS from '@/shared/DateTime/dateTimeLocaleFormats';
import {
  Address,
  AddressBookEntry,
  addressBookEntryFactory,
  AddressBookSuggestion,
  Contact,
  ContactSuggestion,
  createAddress,
  createAddressBookSuggestion,
  createContact,
  createContactSuggestion,
  createDeliveryInstructions,
  createLocation,
  DeliveryInstructions,
  Location,
} from '@/shared/models';
import { NewAddressBookEntry } from '@/shared/models/AddressBookEntry';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import {
  ApiClientResponse,
  GeppettoApiPaginatedResponse,
  GeppettoApiResponse,
} from '@/shared/services/api-client/types';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-address-book/entries.schema';

interface DeliveryInstructionsApiData {
  specialInstructions?: string;
  authorityToLeave: boolean;
}

const DeliveryInstructionsMapper = {
  fromAPI: (data: components['schemas']['DeliveryInstructions']): DeliveryInstructions =>
    createDeliveryInstructions({
      specialInstructions: data.specialInstructions,
      authorityToLeave: data.authorityToLeave || false,
    }),
  toApi(model: DeliveryInstructions): DeliveryInstructionsApiData {
    return {
      specialInstructions: model.specialInstructions,
      authorityToLeave: model.authorityToLeave,
    };
  },
};

interface ContactApiData {
  id?: string;
  name: string;
  phone: string;
  email?: string;
}

const ContactMapper = {
  fromAPI: (data: Partial<ContactApiData>): Contact =>
    createContact({
      id: data.id as string,
      name: data.name?.trim() as string,
      phone: data.phone?.trim() as string,
      email: data.email?.trim() as string,
    }),
  toAPI: (model: Contact): ContactApiData => ({
    id: model.id,
    name: model.name,
    phone: model.phone,
    email: model.email,
  }),
};

export interface ContactSuggestionApiData {
  id?: string;
  attributes: {
    name: string;
    phone: string;
    email?: string;
    deliveryInstructions?: DeliveryInstructionsApiData;
  };
  type: string;
}

const ContactSuggestionMapper = {
  fromAPI: ({ data }: GeppettoApiResponse<ContactSuggestionApiData>) => {
    const contact = ContactMapper.fromAPI(data.attributes);
    contact.id = data.id;

    let deliveryInstructions = createDeliveryInstructions();
    if (data.attributes.deliveryInstructions) {
      deliveryInstructions = DeliveryInstructionsMapper.fromAPI(data.attributes.deliveryInstructions);
    }

    return createContactSuggestion({
      contact,
      deliveryInstructions,
    });
  },
  toAPI: (model: ContactSuggestion): { data: ContactSuggestionApiData } => ({
    data: {
      attributes: {
        name: model.contact.name,
        phone: model.contact.phone,
        email: model.contact.email,
        deliveryInstructions: DeliveryInstructionsMapper.toApi(model.deliveryInstructions),
      },
      type: 'contacts',
    },
  }),
};

interface AddressApiData {
  countryId: string;
  from?: string;
  gnafId?: string;
  id?: string;
  line1: string;
  line2?: string;
  locality: string;
  postcode: string;
  subdivision?: string;
  timeZone: string;
}

const AddressMapper = {
  fromAPI(data: Partial<AddressApiData>): Address {
    return createAddress(data as Address);
  },
  toAPI: (model: Address): AddressApiData => ({
    countryId: model.countryId,
    from: model.from,
    gnafId: model.gnafId,
    id: model.id ? model.id : undefined,
    line1: model.line1,
    line2: model.line2,
    locality: model.locality,
    postcode: model.postcode,
    subdivision: model.subdivision,
    timeZone: model.timeZone,
  }),
};

interface LocationApiData {
  name?: string;
  addressId?: string;
  line2?: string;
  residential: boolean;
  address?: AddressApiData;
}

const LocationMapper = {
  fromAPI(data: LocationApiData): Location {
    const model = createLocation(data as Location);
    model.address = data.address ? AddressMapper.fromAPI(data.address) : undefined;
    return model;
  },
  toAPI(model: Location): LocationApiData {
    return {
      name: model.name,
      addressId: model.addressId,
      line2: model.line2,
      residential: model.residential,
      address: model.address ? AddressMapper.toAPI(model.address) : undefined,
    };
  },
};

type AddressBookEntryMapperFromAPIModel =
  | operations['getAddressBookEntry']['responses']['200']['content']['application/json']
  | operations['createAddressBookEntry']['responses']['201']['content']['application/json'];
const AddressBookEntryMapper = {
  fromAPI({ data }: AddressBookEntryMapperFromAPIModel): AddressBookEntry {
    let contactSuggestions: ContactSuggestion[] = [];
    if ('contacts' in data.attributes && data.attributes.contacts) {
      contactSuggestions = data.attributes.contacts.map(suggestionData => {
        const contact = ContactMapper.fromAPI(suggestionData);
        const deliveryInstructions = suggestionData.deliveryInstructions
          ? DeliveryInstructionsMapper.fromAPI(suggestionData.deliveryInstructions)
          : { specialInstructions: '', authorityToLeave: false };

        return createContactSuggestion({
          contact,
          deliveryInstructions,
        });
      });
    }

    const location = LocationMapper.fromAPI({
      ...data.attributes.location,
      residential: data.attributes.location.residential || false,
      address: {
        ...data.attributes.location.address,
        countryId: data.attributes.location.address.countryId,
      },
      name: data.attributes.name,
      addressId: data.attributes.location.address?.id as string,
    });

    location.address = data.attributes.location.address
      ? AddressMapper.fromAPI(data.attributes.location.address)
      : undefined;

    const palletManagement: AddressBookEntry['palletManagement'] = {};
    if (data.attributes.pallets?.allowedPalletTypes)
      palletManagement.allowedPalletTypes = data.attributes.pallets.allowedPalletTypes;
    if (data.attributes.pallets?.preferredTransferType && data.attributes.pallets?.preferredTransferType !== 'none') {
      palletManagement.preferredTransferType = data.attributes.pallets.preferredTransferType;
    }

    return addressBookEntryFactory.create({
      id: data.id as string,
      siteId: data.attributes.siteId as string,
      quickAccessCode: data.attributes.quickAccessCode as string,
      contactSuggestions,
      location,
      preventConsolidation: data.attributes.preventConsolidation || false,
      requiresDeliveryTimeSlot: data.attributes.requiresDeliveryTimeSlot || false,
      ...((palletManagement.allowedPalletTypes || palletManagement.preferredTransferType) && { palletManagement }),
      operatingHours: {
        differentEachDay: data.attributes.operatingHours?.differentEachDay || false,
        operatingDays: data.attributes.operatingHours?.operatingDays
          ? Object.fromEntries(
              Object.entries(data.attributes.operatingHours?.operatingDays).map(([daySymbol, range]) => [
                daySymbol,
                range
                  ? TimeRange.from({
                      start: Temporal.PlainTime.from(range.open),
                      end: Temporal.PlainTime.from(range.close),
                    })
                  : null,
              ]),
            )
          : {},
      },
    });
  },

  toAPI(
    addressBookEntry: NewAddressBookEntry,
  ): operations['createAddressBookEntry']['requestBody']['content']['application/json'] {
    const timeFormatOptions: Intl.DateTimeFormatOptions = {
      ...DATETIME_LOCALE_FORMATS.TIME_HHMM,
    };
    return {
      data: {
        attributes: {
          siteId: addressBookEntry.siteId,
          name: addressBookEntry.location.name,
          location: LocationMapper.toAPI(addressBookEntry.location) as never, // TODO fix type
          quickAccessCode: addressBookEntry.quickAccessCode,
          preventConsolidation: addressBookEntry.preventConsolidation,
          requiresDeliveryTimeSlot: addressBookEntry.requiresDeliveryTimeSlot,
          pallets: {
            allowedPalletTypes: addressBookEntry.palletManagement?.allowedPalletTypes || null,
            preferredTransferType: addressBookEntry.palletManagement?.preferredTransferType || 'none',
          },
          operatingHours:
            addressBookEntry.operatingHours && !addressBookEntry.location.residential
              ? {
                  differentEachDay: addressBookEntry.operatingHours.differentEachDay,
                  operatingDays: Object.fromEntries(
                    Object.entries(addressBookEntry.operatingHours.operatingDays).map(([day, range]) => [
                      day,
                      range
                        ? {
                            open: range.start.toLocaleString(undefined, timeFormatOptions),
                            close: range.end.toLocaleString(undefined, timeFormatOptions),
                          }
                        : null,
                    ]),
                  ),
                }
              : undefined,
        },
        type: 'entries',
      },
    };
  },
};

export const AddressBookDuplicatesMapper = {
  toAPI(
    addressBookEntry: Pick<AddressBookEntry, 'siteId' | 'location'>,
  ): operations['listDuplicateEntries']['requestBody']['content']['application/json'] {
    if (!addressBookEntry.location.address?.id) throw new MappingError('addressBook location.address.id is required');

    return {
      data: {
        type: 'entries',
        attributes: {
          siteId: addressBookEntry.siteId,
          name: addressBookEntry.location.name,
          location: {
            address: {
              ...addressBookEntry.location.address,
              id: addressBookEntry.location.address.id,
            },
            line2: addressBookEntry.location.line2,
            residential: addressBookEntry.location.residential,
          },
        },
      },
    };
  },
};

export interface AddressBookSuggestionApiData {
  id: string;
  type: string;
  attributes: {
    name: string;
    siteId: string;
    quickAccessCode: string;
    location: {
      address: Address;
      line2: string;
      residential: boolean;
    };
  };
  meta: {
    highlight: {
      name: string;
      quickAccessCode: string;
      location: {
        address: Record<string, unknown>;
        line2: string;
      };
    };
  };
}

const AddressBookSuggestionMapper = {
  fromAPI: (data: AddressBookSuggestionApiData): AddressBookSuggestion => {
    const suggestionLocation = createLocation({
      name: data.attributes.name,
      addressId: data.attributes.location.address.id,
      address: AddressMapper.fromAPI(data.attributes.location.address),
      line2: data.attributes.location.line2,
      residential: data.attributes.location.residential,
    });

    const highlightedLocation = createLocation({
      ...suggestionLocation,
    });
    if (data.meta.highlight && Object.keys(data.meta.highlight).length) {
      highlightedLocation.name = data.meta.highlight.name || data.attributes.name;
      highlightedLocation.line2 = data.meta.highlight.location?.line2 || data.attributes.location.line2;
      highlightedLocation.address = AddressMapper.fromAPI({
        ...data.attributes.location.address,
        ...data.meta.highlight.location?.address,
      });
    }

    return createAddressBookSuggestion({
      id: data.id,
      siteId: data.attributes.siteId,
      quickAccessCode: data.attributes.quickAccessCode,
      location: suggestionLocation,
      highlighted: {
        quickAccessCode: data.meta.highlight.quickAccessCode || data.attributes.quickAccessCode,
        location: highlightedLocation,
      },
    });
  },
};

const AddressBookSuggestionsMapper = {
  fromAPI(
    response: ApiClientResponse<GeppettoApiPaginatedResponse<AddressBookSuggestionApiData>>,
  ): PaginatedItems<AddressBookSuggestion> {
    const { data } = response;
    return {
      items: data.data.map(AddressBookSuggestionMapper.fromAPI),
      total: data.meta.page.totalItems,
      offset: data.meta.page.startIndex,
      pageSize: data.meta.page.itemsPerPage,
      numPages: Math.ceil(data.meta.page.totalItems / data.meta.page.itemsPerPage),
    };
  },
};

export default {
  AddressBookSuggestions: AddressBookSuggestionsMapper,
  AddressBookEntry: AddressBookEntryMapper,
  AddressBookSuggestion: AddressBookSuggestionMapper,
  AddressBookDuplicates: AddressBookDuplicatesMapper,
  ContactSuggestion: ContactSuggestionMapper,
};

import pickBy from 'lodash/pickBy';

import { CustomFieldViewSchema } from '@/shared/customFields/types';
import { Interval } from '@/shared/DateTime';
import {
  Consignment,
  ConsignmentAutoprintPreference,
  consignmentFactory,
  ConsignmentLabelOptions,
  ConsignmentLineItem,
  consignmentLineItemFactory,
  ConsignmentTrackingEvent,
  NewConsignment,
  NewConsignmentLineItem,
} from '@/shared/models';
import { ConsignmentLabelDocuments, ConsignmentPalletData } from '@/shared/models/Consignment/Consignment';
import { isVerifiedAddress } from '@/shared/models/Consignment/ConsignmentAddressee';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import ConsignmentFormDataLineItem from '@/shared/models/Consignment/ConsignmentFormDataLineItem';
import ConsignmentFormSupplementalData from '@/shared/models/Consignment/ConsignmentFormSupplementalData';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';
import {
  mapDangerousGoodsFromAPI,
  mapDangerousGoodsToAPI,
} from '@/shared/services/sender/mappers/dangerousGoodsMappers';
import { mapDeliveryTimeSlotFeaturesToDeliveryTimeSlotRequest } from '@/shared/services/sender/mappers/sharedMappers';
import { PalletCounts } from '@/shared/types/ConsignmentPallets';

import { Temporal } from '@js-temporal/polyfill';

export const mapClientConsignmentLineItemToConsignmentLineItem = (
  lineItem: components['schemas']['ViewLineItem'],
): ConsignmentLineItem => {
  const { height, width, length, volume, weight, description, packagingType } = lineItem;

  return consignmentLineItemFactory.create({
    ...lineItem,
    height,
    width,
    length,
    volume,
    weight,
    description,
    packagingType,
    itemIds: lineItem.itemIds || [],
    pallets: lineItem.pallets ? (lineItem.pallets as PalletCounts) : undefined,
    dangerousGoods: lineItem.dangerousGoods ? lineItem.dangerousGoods.map(mapDangerousGoodsFromAPI) : [],
  });
};

const mapGetConsignmentPalletDetails = (
  response: components['schemas']['ConsignmentPallets'] | undefined,
): ConsignmentPalletData | undefined => {
  if (!response) return undefined;

  if (
    response.transferType !== 'carrier' &&
    response.transferType !== 'receiver' &&
    response.transferType !== 'unknown'
  ) {
    throw new MappingError(`Unknown consignment pallet transfer type '${response.transferType}'`);
  }

  return {
    transferType: response.transferType,
    docketNumbers: response.docketNumbers || {},
  };
};

const mapRelabellingOption = (option: components['schemas']['Link'] | null) => {
  if (!option) return undefined;
  const params = new URLSearchParams(option?.href.split('?')[1]);
  return {
    params: Object.fromEntries(params.entries()),
    meta: option.meta,
  };
};

export const mapRelabellingOptions = (
  options:
    | components['schemas']['UpdateSummaryResource']['links']
    | operations['createConsignment']['responses']['201']['content']['application/json']['links'],
) => ({
  allLabels: mapRelabellingOption(options.allLabels),
  newLabels: 'newLabels' in options ? mapRelabellingOption(options.newLabels) : undefined,
});

export const mapNextSteps = (
  nextSteps?: NonNullable<components['schemas']['UpdateSummaryResource']['meta']>['nextSteps'],
) => {
  return nextSteps;
};

export const mapUpdateSummaryToConsignmentLabelOptions = (
  response: components['schemas']['UpdateSummaryResource'],
): ConsignmentLabelDocuments => ({
  allLabels: mapRelabellingOption(response?.links.allLabels),
  newLabels: mapRelabellingOption(response?.links.newLabels),
});

const mapGetConsignmentCustomField = (data: components['schemas']['CustomField']): CustomFieldViewSchema => ({
  ...data,
});

export const mapClientGetConsignmentResponseToConsignment = (
  response: components['schemas']['ViewConsignmentResource'],
): Consignment => {
  const { attributes, id } = response.data;

  if (!attributes.agreedService?.id) throw new MappingError('agreedService.id is required');

  return consignmentFactory.create({
    id,
    createdAt: attributes.createdAt,
    source: attributes.source,
    consignmentNo: attributes.consignmentNo,
    costCenter: attributes.costCenter || undefined,
    dispatchDate: attributes.dispatchDate, // PlainDate.from(attributes.dispatchDate),
    estimateId: attributes.estimateId,
    manifest: attributes.manifest
      ? {
          ...attributes.manifest,
          addedAt: Temporal.Instant.from(attributes.manifest.addedAt),
        }
      : undefined,
    transfer: attributes.transfer
      ? {
          ...attributes.transfer,
          transferredAt: attributes.transfer?.transferredAt
            ? Temporal.Instant.from(attributes.transfer.transferredAt)
            : undefined,
        }
      : undefined,
    payerAccount: attributes.payerAccount || undefined,
    quoteId: attributes.quoteId,
    agreedService: {
      id: attributes.agreedService.id,
    },
    references: attributes.references || [],
    siteId: attributes.siteId,
    status: attributes.status,
    type: attributes.type,
    // https://flip-eng.atlassian.net/browse/GEPPIE-2759
    // createdAt: attributes.updatedAt, // attributes.createdAt ? Temporal.Instant.from(attributes.createdAt) : undefined,
    updatedAt: attributes.updatedAt, // attributes.updatedAt ? Temporal.Instant.from(attributes.updatedAt) : undefined,

    sender: {
      addressId: attributes.sender.addressId,
      address: attributes.sender.address,
      name: attributes.sender.name,
      line2: attributes.sender.line2,
      residential: attributes.sender.residential || false,
      contactName: attributes.sender.contactName,
      contactPhone: attributes.sender.contactPhone,
      contactEmail: attributes.sender.contactEmail,
      addressBookEntryId: attributes.sender.addressBookEntryId,
      addressBookContactId: attributes.sender.addressBookContactId,
    },
    receiver: {
      addressId: attributes.receiver.addressId,
      address: attributes.receiver.address,
      name: attributes.receiver.name,
      line2: attributes.receiver.line2,
      residential: attributes.receiver.residential || false,
      contactName: attributes.receiver.contactName,
      contactPhone: attributes.receiver.contactPhone,
      contactEmail: attributes.receiver.contactEmail,
      authorityToLeave: attributes.receiver.authorityToLeave,
      specialInstructions: attributes.receiver.specialInstructions,
      addressBookEntryId: attributes.receiver.addressBookEntryId,
      addressBookContactId: attributes.receiver.addressBookContactId,
      deliveryTimeSlot: attributes.receiver.deliveryTimeSlot
        ? {
            window: Interval.from(attributes.receiver.deliveryTimeSlot?.slot.window),
            recurrences: attributes.receiver.deliveryTimeSlot?.slot.recurrences,
          }
        : undefined,
      deliveryReferences: attributes.receiver.deliveryTimeSlot?.references || [],
      preventConsolidation: attributes.receiver.preventConsolidation || false,
    },

    lineItems: attributes.lineItems.map(mapClientConsignmentLineItemToConsignmentLineItem),
    tracking: attributes.tracking?.map(ConsignmentTrackingEvent.createFromApi) || [],

    pallets: mapGetConsignmentPalletDetails(attributes.pallets),
    dangerousGoodsDeclaration: attributes.dangerousGoodsDeclaration,
    issues: attributes.issues ? pickBy(attributes.issues, v => v !== undefined) : undefined,
    transferPolicy: attributes.transferPolicy,
    linkedOrders: attributes.linkedOrders,
    customFields: attributes.customFields ? attributes.customFields.map(mapGetConsignmentCustomField) : undefined,
    pickupRequests:
      attributes.pickupRequests?.map(r => ({
        requestId: r.requestId,
        pickupId: r.pickupId,
        status: r.status,
        createdAt: Temporal.Instant.from(r.createdAt),
        updatedAt: Temporal.Instant.from(r.updatedAt),
      })) || [],
  });
};

export const consignmentLineItemsToApi = (
  item: NewConsignmentLineItem | ConsignmentFormDataLineItem,
): components['schemas']['LineItem'] => {
  if (!item.length) throw new MappingError('length is required');
  if (!item.width) throw new MappingError('width is required');
  if (!item.height) throw new MappingError('height is required');
  if (!item.weight) throw new MappingError('weight is required');
  if (!item.description) throw new MappingError('description is required');
  if (!item.packagingType) throw new MappingError('packagingType is required');

  return {
    ...(item.id && { id: item.id }), // not present for create
    description: item.description,
    packagingType: item.packagingType,
    quantity: Math.round(item.quantity),
    length: Math.round(item.length),
    width: Math.round(item.width),
    height: Math.round(item.height),
    weight: Math.round(item.weight),
    reference: item.reference || undefined,
    shippingItemPresetId: item.shippingItemPresetId || undefined,
    pallets: item.pallets,
    dangerousGoods: item.dangerousGoods ? item.dangerousGoods.map(mapDangerousGoodsToAPI) : undefined,
  };
};

export const mapConsignmentPalletData = (
  consignment: NewConsignment | ConsignmentFormData,
): components['schemas']['ConsignmentPallets'] | undefined => {
  // check if the consignment has any pallets
  const palletCounts = consignment.lineItems.reduce<Record<string, number>>((acc, lineItem) => {
    Object.entries(lineItem.pallets || {}).forEach(([lender, count]) => {
      if (count) {
        acc[lender] = acc[lender] ? acc[lender] + count : count;
      }
    });
    return acc;
  }, {});
  const hasPallets = Object.keys(palletCounts).length > 0;
  if (!hasPallets) return undefined; // unset the pallet data if there are no line items with pallets

  const onlyOtherPallets = hasPallets && !Object.keys(palletCounts).some(l => l !== 'other');
  if (onlyOtherPallets) return { transferType: 'unknown', docketNumbers: null };
  if (!consignment.pallets) return null; // the user hasn't provided pallet data yet

  return {
    docketNumbers: consignment.pallets.docketNumbers || null,
    transferType: consignment.pallets.transferType || 'unknown',
  };
};

type ConsignmentCreateResource = operations['createConsignment']['requestBody']['content']['application/json'];
export const mapNewConsignmentToClientCreateConsignmentResource = ({
  consignment,
  autoprint,
}: {
  consignment: NewConsignment;
  autoprint?: ConsignmentAutoprintPreference;
}): ConsignmentCreateResource => {
  if (!consignment.siteId) throw new MappingError('siteId is required');
  if (!consignment.type) throw new MappingError('type is required');
  if (!consignment.quoteId) throw new MappingError('quote is required');
  if (!consignment.dispatchDate) throw new MappingError('dispatchDate is required');
  if (!consignment.sender.addressId) throw new MappingError('sender.addressId is required');
  if (!consignment.receiver.addressId) throw new MappingError('receiver.addressId is required');

  const consignmentRequest: ConsignmentCreateResource = {
    data: {
      id: consignment.id,
      type: 'consignments',
      attributes: {
        type: consignment.type,
        siteId: consignment.siteId,
        references: consignment.references,
        payerAccount: consignment.payerAccount,
        quoteId: consignment.quoteId,
        costCenter: consignment.costCenter,
        dispatchDate: consignment.dispatchDate,
        sender: {
          addressId: consignment.sender.addressId,
          contactEmail: consignment.sender.contactEmail,
          contactName: consignment.sender.contactName,
          contactPhone: consignment.sender.contactPhone,
          line2: consignment.sender.line2,
          name: consignment.sender.name,
          residential: consignment.sender.residential,
          addressBookEntryId: consignment.sender.addressBookEntryId,
          addressBookContactId: consignment.sender.addressBookContactId,
        },
        receiver: {
          addressId: consignment.receiver.addressId,
          contactEmail: consignment.receiver.contactEmail,
          contactName: consignment.receiver.contactName,
          contactPhone: consignment.receiver.contactPhone,
          line2: consignment.receiver.line2,
          name: consignment.receiver.name,
          residential: consignment.receiver.residential,
          authorityToLeave: consignment.receiver.authorityToLeave,
          specialInstructions: consignment.receiver.specialInstructions,
          addressBookEntryId: consignment.receiver.addressBookEntryId,
          addressBookContactId: consignment.receiver.addressBookContactId,
          preventConsolidation: consignment.receiver.preventConsolidation,
        },
        lineItems: consignment.lineItems.map(consignmentLineItemsToApi),
        pallets: mapConsignmentPalletData(consignment),
        estimateId: consignment.estimateId,
        orderId: consignment.orderId,
        autoprint: {
          connotes: { enabled: autoprint?.connote || false },
          // connotes: { enabled: false },
          labels: {
            enabled: autoprint?.labels || false,
            // enabled: false,
            copyCount: autoprint?.labelCopies || 0,
          },
        },
        dangerousGoodsDeclaration: consignment.dangerousGoodsDeclaration,
        customFields: consignment.customFields,
      },
    },
  };

  if (consignment.receiver.deliveryTimeSlot?.requiresDTS) {
    if (!isVerifiedAddress(consignment.receiver.address)) {
      throw new MappingError('receiver.address must be a verified address to support DTS');
    }

    if (!consignment.receiver.address?.timeZone)
      throw new MappingError('receiver.address.timeZone is required for DTS');

    consignmentRequest.data.attributes.receiver.deliveryTimeSlot = mapDeliveryTimeSlotFeaturesToDeliveryTimeSlotRequest(
      consignment.receiver.deliveryTimeSlot,
      consignment.receiver.address.timeZone,
    );
  }

  return consignmentRequest;
};

export const mapConsignmentFormDataToClientCreateConsignmentResource = ({
  formData,
  supplementalData,
}: {
  formData: ConsignmentFormData;
  supplementalData: ConsignmentFormSupplementalData;
}): ConsignmentCreateResource => {
  if (!supplementalData.siteId) throw new MappingError('siteId is required');
  if (!supplementalData.type) throw new MappingError('type is required');
  if (!formData.quoteId) throw new MappingError('quote is required');
  if (!formData.dispatchDate) throw new MappingError('dispatchDate is required');
  if (!formData.sender.location.addressId) throw new MappingError('sender.location.addressId is required');
  if (!formData.sender.location.name) throw new MappingError('sender.contact.name is required');
  if (!formData.receiver.location.addressId) throw new MappingError('receiver.addressId is required');
  if (!formData.receiver.location.name) throw new MappingError('sender.contact.name is required');

  const consignmentRequest: ConsignmentCreateResource = {
    data: {
      id: supplementalData.consignmentId,
      type: 'consignments',
      attributes: {
        type: supplementalData.type,
        siteId: supplementalData.siteId,
        references: formData.references,
        payerAccount: formData.payerAccount,
        quoteId: formData.quoteId,
        costCenter: formData.costCenter,
        dispatchDate: formData.dispatchDate.toString(),
        sender: {
          name: formData.sender.location.name,
          addressId: formData.sender.location.address?.id,
          line2: formData.sender.location.line2,
          residential: formData.sender.location.residential,
          addressBookEntryId: formData.sender.addressBookEntryId,
          addressBookContactId: formData.sender.contact.id,
          contactEmail: formData.sender.contact.email,
          contactName: formData.sender.contact.name,
          contactPhone: formData.sender.contact.phone,
        },
        receiver: {
          name: formData.receiver.location.name,
          addressId: formData.receiver.location.addressId,
          line2: formData.receiver.location.line2,
          residential: formData.receiver.location.residential,
          authorityToLeave: formData.receiver.deliveryInstructions?.authorityToLeave || false,
          specialInstructions: formData.receiver.deliveryInstructions?.specialInstructions,
          contactName: formData.receiver.contact.name,
          contactEmail: formData.receiver.contact.email,
          contactPhone: formData.receiver.contact.phone,
          preventConsolidation: supplementalData.receiverAddressBookEntry?.preventConsolidation,
          addressBookEntryId: formData.receiver.addressBookEntryId,
          addressBookContactId: formData.receiver.contact.id,
        },
        lineItems: formData.lineItems.map(consignmentLineItemsToApi),
        pallets: mapConsignmentPalletData(formData),
        estimateId: supplementalData.estimateId,
        orderId: supplementalData.orderId,
        autoprint: {
          connotes: { enabled: supplementalData.autoprintPreferences.connote || false },
          // connotes: { enabled: false },
          labels: {
            enabled: supplementalData.autoprintPreferences.labels || false,
            // enabled: false,
            copyCount: supplementalData.autoprintPreferences.labelCopies || 0,
          },
        },
        dangerousGoodsDeclaration: {
          excludesDangerousGoods: formData.excludesDangerousGoods,
        },
        customFields: formData.customFields,
      },
    },
  };

  if (formData.receiverDeliveryTimeSlot?.requiresDTS) {
    if (!formData.receiver.location.address?.timeZone)
      throw new MappingError('receiver.address.timeZone is required for DTS');

    consignmentRequest.data.attributes.receiver.deliveryTimeSlot = mapDeliveryTimeSlotFeaturesToDeliveryTimeSlotRequest(
      formData.receiverDeliveryTimeSlot,
      formData.receiver.location.address.timeZone,
    );
  }

  return consignmentRequest;
};

type ConsignmentUpdateResource = operations['updateConsignment']['requestBody']['content']['application/json'];
export const mapNewConsignmentToClientUpdateConsignmentResource = ({
  consignment,
  autoprint,
  labelOptions,
}: {
  consignment: NewConsignment;
  autoprint?: ConsignmentAutoprintPreference;
  labelOptions: ConsignmentLabelOptions;
}): ConsignmentUpdateResource => {
  const update: ConsignmentUpdateResource = mapNewConsignmentToClientCreateConsignmentResource({
    consignment,
    autoprint,
  });

  if (update.data.attributes.autoprint && update.data.attributes.autoprint.labels.enabled) {
    update.data.attributes.autoprint.labels.generateLabellingInstructions =
      labelOptions.generateInstructions === 'true';
    update.data.attributes.autoprint.labels.includeItems = labelOptions.newItemsOnly ? 'new' : 'all';
  }

  return update;
};

export const mapConsignmentFormDataToClientUpdateConsignmentResource = ({
  formData,
  supplementalData,
}: {
  formData: ConsignmentFormData;
  supplementalData: ConsignmentFormSupplementalData;
}): ConsignmentUpdateResource => {
  const update: ConsignmentUpdateResource = mapConsignmentFormDataToClientCreateConsignmentResource({
    formData,
    supplementalData,
  });

  if (update.data.attributes.autoprint && update.data.attributes.autoprint.labels.enabled) {
    update.data.attributes.autoprint.labels.generateLabellingInstructions =
      supplementalData.labelOptions.generateInstructions === 'true';
    update.data.attributes.autoprint.labels.includeItems = supplementalData.labelOptions.newItemsOnly ? 'new' : 'all';
  }

  return update;
};

import Location, { createLocation } from '@/shared/models/Location';

interface AddressBookSuggestion {
  id: string;
  siteId: string;
  quickAccessCode: string;
  location: Location;
  highlighted: {
    quickAccessCode: string;
    location: Location;
  };
}

export const newAddressBookSuggestion = (): AddressBookSuggestion => ({
  id: '',
  siteId: '',
  quickAccessCode: '',
  location: createLocation(),
  highlighted: {
    quickAccessCode: '',
    location: createLocation(),
  },
});

export const createAddressBookSuggestion = (
  {
    id,
    siteId,
    quickAccessCode,
    location,
    highlighted,
  }: AddressBookSuggestion | undefined = newAddressBookSuggestion(),
): AddressBookSuggestion => ({
  id,
  siteId,
  quickAccessCode,
  location,
  highlighted,
});

export default AddressBookSuggestion;

export interface AppFlag {
  type: 'string' | 'boolean' | 'enum' | 'enums';
  options?: string[];
  default: boolean | string | string[];
  group?: string;
  description?: string;
}

const appFlags: Record<string, AppFlag> = {
  sensitive: {
    description: 'set to "hide" to preview sensitive masked elements during development',
    type: 'enum',
    options: ['hide', 'show', 'blur'],
    default: 'hide',
  },

  // set to 'type' or 'version' to see the prompt
  browserCheck: {
    description: 'set to "type" or "version" to see the prompt',
    type: 'enum',
    options: ['pass', 'type', 'version'],
    default: 'pass',
  },

  // enable dangerous goods features in development
  dgSite: {
    description: 'Are DGs enabled for the current site?',
    group: 'Dangerous Goods',
    type: 'enum',
    options: ['default', 'enabled', 'disabled'],
    default: 'default',
  },

  dgSitePast: {
    description: 'WERE DGs enabled for the current site?',
    group: 'Dangerous Goods',
    type: 'enum',
    options: ['default', 'enabled', 'disabled'],
    default: 'default',
  },

  dgUser: {
    description: 'Are DGs enabled for the current user?',
    group: 'Dangerous Goods',
    type: 'enum',
    options: ['default', 'enabled', 'disabled'],
    default: 'default',
  },

  // https://flip-eng.atlassian.net/browse/GEPPIE-8785
  dgNotifiable: {
    description: 'Enable to show Notifiable DGs',
    group: 'Dangerous Goods',
    type: 'boolean',
    default: false,
  },

  orderIntegration: {
    description: 'Enable order integration',
    type: 'boolean',
    default: false,
  },

  // https://flip-eng.atlassian.net/browse/GP-80
  newConsignmentForm: {
    group: 'New Consignment Form',
    description: 'Enable the new consignment form',
    type: 'boolean',
    default: true,
  },
  debugConsignmentForm: {
    group: 'New Consignment Form',
    description: 'Show debug info in new consignment form',
    type: 'boolean',
    default: false,
  },
  noDocuments: {
    description: 'Disable label / connote popups after consigning',
    type: 'boolean',
    default: false,
  },
  addressBookBulkImport: {
    description: 'Enable address book bulk import UI',
    type: 'boolean',
    default: false,
  },
} as const;

export default appFlags;

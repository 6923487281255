import DangerousGoodsPackingGroup from '@/shared/models/DangerousGoods/DangerousGoodsPackingGroup';

import DangerousGoodsQuantity from './DangerousGoodsQuantity';

export interface DangerousGoodsPreset {
  id: UUID;
  siteId: UUID;
  unNumber: string;
  properShippingName: string;
  technicalName?: string;
  productDescription?: string;
  classOrDivision?: string;
  subsidiaryRisks?: string[];
  packingGroup?: DangerousGoodsPackingGroup;
  notifiable: boolean;
  notification?: string;
  receptacle?: string;
  receptacleAggregateQuantity?: Partial<DangerousGoodsQuantity>;
}

function create({
  id,
  siteId,
  notifiable,
  properShippingName,
  technicalName,
  productDescription,
  unNumber,
  classOrDivision,
  subsidiaryRisks,
  packingGroup,
  receptacle,
  receptacleAggregateQuantity,
  notification,
}: DangerousGoodsPreset): DangerousGoodsPreset {
  return {
    id,
    siteId,
    notifiable,
    properShippingName,
    technicalName,
    productDescription,
    unNumber,
    classOrDivision,
    subsidiaryRisks,
    packingGroup,
    receptacle,
    receptacleAggregateQuantity,
    notification,
  };
}

export const dangerousGoodsPresetFactory = {
  create,
};

export const dangerousGoodsNotifications = [
  'Contains dangerous goods packed in limited quantities.',
  'Lithium batteries in compliance with SP188 of the Australian Dangerous Goods Code.',
];

export default DangerousGoodsPreset;

import { TimeRange } from '@/shared/DateTime';
import { DAY_OF_WEEK } from '@/shared/DateTime/Calendar';
import { ContactSuggestion, Location } from '@/shared/models';
import { PalletLender, PalletTransferType } from '@/shared/types/ConsignmentPallets';

type OperatingPeriod = TimeRange;

interface AddressBookEntry {
  id: string;
  siteId: string;
  quickAccessCode: string;
  location: Location;
  contactSuggestions: ContactSuggestion[];
  preventConsolidation: boolean;
  requiresDeliveryTimeSlot: boolean;
  palletManagement?: {
    allowedPalletTypes?: PalletLender[];
    preferredTransferType?: Exclude<PalletTransferType, 'unknown'>;
  };
  operatingHours: {
    differentEachDay: boolean;
    operatingDays: Partial<Record<DAY_OF_WEEK, OperatingPeriod | null>>;
  };
}

export interface NewAddressBookEntry {
  siteId: string;
  quickAccessCode: string;
  location: Location;
  contactSuggestions: ContactSuggestion[];
  preventConsolidation: boolean;
  requiresDeliveryTimeSlot: boolean;
  palletManagement?: {
    allowedPalletTypes?: PalletLender[];
    preferredTransferType?: Exclude<PalletTransferType, 'unknown'>;
  };
  operatingHours?: {
    differentEachDay: boolean;
    operatingDays: Partial<Record<DAY_OF_WEEK, OperatingPeriod | null>>;
  };
}

const create = ({
  id,
  siteId,
  quickAccessCode,
  location,
  contactSuggestions,
  preventConsolidation,
  requiresDeliveryTimeSlot,
  palletManagement,
  operatingHours,
}: AddressBookEntry): AddressBookEntry => ({
  id,
  siteId,
  quickAccessCode,
  location,
  contactSuggestions,
  preventConsolidation,
  requiresDeliveryTimeSlot,
  ...(palletManagement && { palletManagement }),
  operatingHours,
});

export const addressBookEntryFactory = {
  create,
};

export default AddressBookEntry;

/**
 * ManifestSummary model
 * represents a single API result when searching/listing manifests
 */

import { createOrganisation } from '@/shared/models/Organisation';
import { Address, Organisation } from '@/shared/models/index';

export interface ManifestSummary {
  id: string;
  created: string;
  consignmentType: string;
  manifestNumber?: string;
  dispatchDate: string;
  itemQuantity: number;
  volume: number;
  weight: number;
  organisation: Organisation;
  site: {
    id: UUID;
  };
  carrier: Organisation;
  agreedServices?: {
    id: UUID;
    name: string;
  }[];
  senderName: string;
  dispatchAddress: Address;
  hasDangerousGoods: boolean;
}

const create = ({
  id,
  created,
  consignmentType,
  manifestNumber,
  dispatchDate,
  itemQuantity,
  volume,
  weight,
  organisation,
  site,
  carrier,
  agreedServices = [],
  senderName,
  dispatchAddress,
  hasDangerousGoods,
}: ManifestSummary) => ({
  id,
  created,
  consignmentType,
  manifestNumber,
  dispatchDate,
  itemQuantity,
  volume,
  weight,
  site,
  organisation: createOrganisation(organisation),
  carrier: createOrganisation(carrier),
  agreedServices,
  senderName,
  dispatchAddress,
  hasDangerousGoods,
});

const manifestSummaryFactory = {
  create,
};

export default manifestSummaryFactory;

import cloneDeep from 'lodash/cloneDeep';
import { computed, ComputedRef, getCurrentInstance, inject, unref } from 'vue';

import { TimeRange } from '@/shared/DateTime';
import { AgreedService, PackagingType, Site } from '@/shared/models';
import { dynamicKeySort } from '@/shared/utils/string-utils';

import contextInjectionKey from '@App/context/contextInjection';

export const useContext = () => {
  const ctx = inject(contextInjectionKey);
  if (!ctx) throw new Error('Unable to get context');
  return ctx;
};

export const hasContext = () => !!(getCurrentInstance() && inject(contextInjectionKey, undefined));

export const useSite = () => {
  const context = useContext();
  return computed(() => context.value?.site);
};

export const useSiteId = () => {
  const site = useSite();
  return computed(() => site.value?.id);
};

export const useMaybeOrg = () => {
  const context = useContext();
  return computed(() => context.value.org);
};

export const useOrg = () => {
  // To be used in the context where we know the org has defined value (e.g. safeguarded by router)
  const context = useContext();
  return computed(() => {
    const orgVal = context.value.org;
    if (!orgVal) throw new Error('Unable to get Org (value is undefined)');
    return orgVal;
  });
};

export const useOrgId = () => {
  const org = useOrg();
  return computed(() => org.value.id);
};

/**
 * Return all agreed services for the current org.
 */
export const useOrgAgreedServices = () => {
  const context = useContext();

  const services = computed(() => {
    const contextRaw = unref(context);
    if (contextRaw === undefined) return [];
    const { org } = contextRaw;

    if (org && 'sites' in org) {
      return Object.values(org.sites).reduce(
        (acc: AgreedService[], orgSite: Site) => [...acc, ...orgSite.agreedServices],
        [],
      );
    }
    return [];
  });

  const getService = (agreedServiceId?: UUID) => services.value.find(s => s.id === agreedServiceId);

  return { services, getService };
};

/**
 * If a site context is set, return all agreed services for the current site, otherwise return all agreed services for
 * the current org.
 */
export const useAgreedServices = () => {
  const { services: orgServices } = useOrgAgreedServices();
  const context = useContext();

  const services = computed(() => {
    const contextRaw = unref(context);
    if (contextRaw === undefined) return [];

    const { site } = contextRaw;
    if (site) return site.agreedServices;

    // we're probably in global view, return the org services (this is the same list as useOrgAgreedServices above)
    return orgServices.value;
  });

  const getService = (agreedServiceId?: UUID) => services.value.find(s => s.id === agreedServiceId);

  return { services, getService };
};

export const useCostCentreConfiguration = (): ComputedRef<Site['costCentreConfiguration']> => {
  const context = useContext();
  return computed(() => {
    const contextRaw = unref(context);
    if (contextRaw === undefined) throw new Error('Context is undefined');

    const { site } = contextRaw;
    if (site) {
      return site.costCentreConfiguration;
    }

    throw new Error('Site in not defined (Cost centres can be used only within a single site context)');
  });
};

// sort alphabetically with Other always last
const sortByName = dynamicKeySort('name');
const packagingTypeSort = (a: PackagingType, b: PackagingType) => (a.name === 'Other' ? 1 : sortByName(a, b));

export const usePackagingTypes = (): ComputedRef<PackagingType[]> => {
  const context = useContext();
  return computed(() => {
    const contextRaw = unref(context);
    if (contextRaw === undefined) return [];
    const { site, org } = contextRaw;

    if (site) {
      return cloneDeep(site.packagingTypes).sort(packagingTypeSort);
    }
    if (org && 'sites' in org) {
      return Object.values(org.sites)
        .reduce(
          (acc: PackagingType[], orgSite) => [
            ...acc,
            ...orgSite.packagingTypes.filter(pType => !acc.find(existingType => pType.name === existingType.name)),
          ],
          [],
        )
        .sort(packagingTypeSort);
    }
    return [];
  });
};

export const useSitePickupWindow = (): ComputedRef<TimeRange | undefined> => {
  const site = useSite();
  return computed(() => {
    const siteValue = unref(site);
    if (siteValue === undefined) return undefined;

    return siteValue.siteAccess.defaultPickupWindow;
  });
};

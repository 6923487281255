import { Quote, QuoteWithService } from '@/shared/models';

import { useOrgAgreedServices } from '@App/context';
import { AgreedServiceNotFoundError } from '@/shared/models/Quote/QuoteSetWithServices';

export default function useQuoteDetails() {
  const { getService } = useOrgAgreedServices();

  const getAgreedServiceForQuote = (quote: Quote) => getService(quote.agreedServiceId);

  const getQuoteDetails = (quote: Quote): QuoteWithService => {
    const agreedService = getAgreedServiceForQuote(quote);
    if (!agreedService) {
      throw new AgreedServiceNotFoundError(quote);
    }

    return {
      ...quote,
      agreedServiceModel: agreedService,
    };
  };

  return {
    getAgreedServiceForQuote,
    getQuoteDetails,
  };
}

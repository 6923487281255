import { defineComponent, h, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ProgressIndicator from '@/shared/components/ProgressIndicator.vue';

const StatefulRedirect = defineComponent({
  props: {
    beforeRedirect: { type: Function, required: false, default: () => {} },
    redirectTo: { type: Function, required: true },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    onBeforeMount(async () => {
      try {
        props.beforeRedirect();
      } catch (e) {
        logger.error('[StatefulRedirect] beforeRedirect step failed', { cause: e });
      }

      await router.push((await props.redirectTo({ router, store })) || '');
    });

    return () => h(ProgressIndicator);
  },
});

export default StatefulRedirect;

import APIClient from '@/shared/services/api-client';
import CustomFieldsClient from '@/shared/services/customFields/CustomFieldsClient';
import { onError } from '@App/common/services/apiErrorHandler';
import AutoprintClient from '@/shared/services/sender/AutoprintClient';
import ConsolidationClient from '@/shared/services/sender/ConsolidationClient';
import EstimateClient from '@/shared/services/sender/EstimateClient';
import ManifestClient from '@/shared/services/sender/ManifestClient';
import OrderClient from '@/shared/services/sender/OrderClient';
import TrackingClient from '@/shared/services/sender/TrackingClient';
import QuoteClient from '@/shared/services/sender/QuoteClient';
import PickupClient from '@/shared/services/sender/PickupClient';
import ConsignmentClient from '@/shared/services/sender/ConsignmentClient';

if (
  !import.meta.env.GEPPETTO_SERVICE_SENDER_URL ||
  !import.meta.env.GEPPETTO_MAX_SITE_ID_PARAMS ||
  !import.meta.env.GEPPETTO_DEFAULT_RESOURCES_PER_PAGE
) {
  throw new Error('GEPPETTO .env not correctly configured');
}

const maxSiteIdParams = Number(import.meta.env.GEPPETTO_MAX_SITE_ID_PARAMS);
const resourcesPerPage = Number(import.meta.env.GEPPETTO_DEFAULT_RESOURCES_PER_PAGE);

const baseUrl = import.meta.env.GEPPETTO_SERVICE_SENDER_URL;
const apiClient = new APIClient(baseUrl, { onError });

export const autoprintService = new AutoprintClient(apiClient);
export const consignmentService = new ConsignmentClient(apiClient, { maxSiteIdParams, baseUrl });
export const consolidationService = new ConsolidationClient(apiClient);
export const customFieldsService = new CustomFieldsClient(apiClient);
export const estimateService = new EstimateClient(apiClient, { maxSiteIdParams, resourcesPerPage });
export const manifestService = new ManifestClient(apiClient, { maxSiteIdParams, baseUrl });
export const orderService = new OrderClient(apiClient);
export const pickupService = new PickupClient(apiClient, { maxSiteIdParams, resourcesPerPage });
export const quoteService = new QuoteClient(apiClient);
export const trackingService = new TrackingClient({ baseUrl });

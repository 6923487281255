import { Temporal } from '@js-temporal/polyfill';

import DangerousGoodsPreset from './DangerousGoodsPreset';

export interface DangerousGoodsPresetSummary extends Omit<DangerousGoodsPreset, 'notification'> {
  lastUsed?: Temporal.Instant;
}

function create({
  id,
  siteId,
  properShippingName,
  technicalName,
  productDescription,
  notifiable,
  unNumber,
  classOrDivision,
  subsidiaryRisks,
  packingGroup,
  receptacle,
  receptacleAggregateQuantity,
  lastUsed,
}: DangerousGoodsPresetSummary): DangerousGoodsPresetSummary {
  return {
    id,
    siteId,
    properShippingName,
    technicalName,
    productDescription,
    notifiable,
    unNumber,
    classOrDivision,
    subsidiaryRisks,
    packingGroup,
    receptacle,
    receptacleAggregateQuantity,
    lastUsed,
  };
}

export const dangerousGoodsPresetSummaryFactory = {
  create,
};

export default DangerousGoodsPresetSummary;

<template>
  <span ref="tooltip" :class="rootClasses">
    <transition :name="newAnimation">
      <div
        v-show="active && (isActive || always)"
        ref="content"
        v-sensitive="sensitive"
        :class="['tooltip-content', contentClass]"
        :style="style"
      >
        <div class="tooltip-content-inner">
          <template v-if="$slots.content">
            <slot name="content"></slot>
          </template>
          <template v-else-if="label">{{ label }}</template>
        </div>
      </div>
    </transition>
    <div
      ref="trigger"
      class="tooltip-trigger hidden-if-empty"
      @click="onClick"
      @contextmenu="onContextMenu"
      @mouseenter="onHover"
      @focus.capture="onFocus"
      @mouseleave="close"
    >
      <slot ref="slot"></slot>
    </div>
  </span>
</template>

<script>
  /* eslint-disable vue/require-default-prop */
  /* eslint-disable vue/multi-word-component-names */
  export default {
    name: 'Tooltip',
    props: {
      active: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: 'is-tooltip-dark',
      },
      label: String,
      delay: Number,
      position: {
        type: String,
        default: 'is-top',
        validator(value) {
          return ['is-top', 'is-bottom', 'is-left', 'is-right'].indexOf(value) > -1;
        },
      },
      triggers: {
        type: Array,
        default: () => ['hover'],
      },
      always: Boolean,
      square: Boolean,
      dashed: Boolean,
      multilined: Boolean,
      fixedWidth: { type: Boolean, default: false },
      startOpen: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'is-medium',
      },
      animated: {
        type: Boolean,
        default: true,
      },
      animation: {
        type: String,
        default: 'fade',
      },
      contentClass: String,
      autoClose: {
        type: [Array, Boolean],
        default: true,
      },
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['triggered', 'opened', 'closed'],
    data() {
      return {
        isActive: false,
        style: {},
        timer: null,
      };
    },
    computed: {
      rootClasses() {
        return [
          'tooltip',
          this.type,
          this.position,
          this.size,
          {
            'is-square': this.square,
            'is-always': this.always,
            'is-multiline': this.multilined,
            'is-dashed': this.dashed,
            'has-fixed-width': this.fixedWidth,
          },
        ];
      },
      newAnimation() {
        return this.animated ? this.animation : undefined;
      },
    },
    watch: {
      isActive(newValue, oldValue) {
        if (newValue && !oldValue) this.$emit('opened');
        else if (!newValue && oldValue) this.$emit('closed');
      },
    },

    created() {
      if (typeof window !== 'undefined') {
        document.addEventListener('click', this.clickedOutside);
        document.addEventListener('keyup', this.keyPress);
      }
      if (this.startOpen) this.isActive = true;
    },
    beforeUnmount() {
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', this.clickedOutside);
        document.removeEventListener('keyup', this.keyPress);
      }
    },
    methods: {
      onClick() {
        if (this.triggers.indexOf('click') < 0) return;
        // if not active, toggle after clickOutside event
        // this fixes toggling programmatic
        this.$nextTick(() => {
          setTimeout(() => this.open());
        });
      },
      onHover() {
        if (this.triggers.indexOf('hover') < 0) return;
        this.open();
      },
      onContextMenu(e) {
        if (this.triggers.indexOf('contextmenu') < 0) return;
        e.preventDefault();
        this.open();
      },
      onFocus() {
        if (this.triggers.indexOf('focus') < 0) return;
        this.open();
      },
      open() {
        if (this.delay) {
          this.timer = setTimeout(() => {
            this.isActive = true;
            this.timer = null;
            this.$emit('triggered');
          }, this.delay);
        } else {
          this.isActive = true;
          this.$emit('triggered');
        }
      },
      close() {
        if (typeof this.autoClose === 'boolean') {
          this.isActive = !this.autoClose;
          if (this.autoClose && this.timer) clearTimeout(this.timer);
        }
      },
      /**
       * Close tooltip if clicked outside.
       */
      clickedOutside(event) {
        if (this.isActive) {
          if (Array.isArray(this.autoClose)) {
            if (this.autoClose.indexOf('outside') >= 0) {
              if (!this.isInWhiteList(event.target)) this.isActive = false;
            } else if (this.autoClose.indexOf('inside') >= 0) {
              if (this.isInWhiteList(event.target)) this.isActive = false;
            }
          }
        }
      },
      /**
       * Keypress event that is bound to the document
       */
      keyPress({ key }) {
        if (this.isActive && (key === 'Escape' || key === 'Esc')) {
          if (Array.isArray(this.autoClose)) {
            if (this.autoClose.indexOf('escape') >= 0) this.isActive = false;
          }
        }
      },
      /**
       * White-listed items to not close when clicked.
       */
      isInWhiteList(el) {
        if (el === this.$refs.content) return true;
        // All chidren from content
        if (this.$refs.content !== undefined) {
          const children = this.$refs.content.querySelectorAll('*');
          for (const child of children) {
            if (el === child) {
              return true;
            }
          }
        }
        return false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  $tooltip-arrow-size: 5px !default;
  $tooltip-arrow-margin: 2px !default;
  $tooltip-multiline-sizes: (
    small: 180px,
    medium: 240px,
    large: 300px,
    xlarge: 420px, // requested by Mackenzie for GEPPIE-3971
  ) !default;

  $tooltip-bonus-colors: (
    // this is the new tooltip background-color added by Mackenzie
    'tooltip-dark': ($copy-100, $white)
  );
  $tooltip-colors: map-merge($colors, $tooltip-bonus-colors) !default;

  @mixin tooltip-arrow-color($direction, $color) {
    @if ($direction == 'is-top') {
      border-top-color: $color;
    } @else if ($direction == 'is-bottom') {
      border-bottom-color: $color;
    } @else if ($direction == 'is-right') {
      border-right-color: $color;
    } @else if ($direction == 'is-left') {
      border-left-color: $color;
    }
  }

  @mixin tooltip($direction) {
    &.#{$direction} {
      .tooltip-content {
        @if ($direction == 'is-top') {
          top: auto;
          right: auto;
          bottom: calc(100% + #{$tooltip-arrow-size} + #{$tooltip-arrow-margin});
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        } @else if ($direction == 'is-bottom') {
          top: calc(100% + #{$tooltip-arrow-size} + #{$tooltip-arrow-margin});
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        } @else if ($direction == 'is-right') {
          top: 50%;
          right: auto;
          bottom: auto;
          left: calc(100% + #{$tooltip-arrow-size} + #{$tooltip-arrow-margin});
          transform: translateY(-50%);
        } @else if ($direction == 'is-left') {
          top: 50%;
          right: calc(100% + #{$tooltip-arrow-size} + #{$tooltip-arrow-margin});
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
        }
      }

      .tooltip-content::before {
        @if ($direction == 'is-bottom') {
          top: auto;
          right: auto;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-right: $tooltip-arrow-size solid transparent;
          border-bottom: $tooltip-arrow-size solid $primary;
          border-left: $tooltip-arrow-size solid transparent;
        } @else if ($direction == 'is-top') {
          top: 100%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translateX(-50%);
          border-top: $tooltip-arrow-size solid $primary;
          border-right: $tooltip-arrow-size solid transparent;
          border-left: $tooltip-arrow-size solid transparent;
        } @else if ($direction == 'is-left') {
          top: 50%;
          right: auto;
          bottom: auto;
          left: 100%;
          transform: translateY(-50%);
          border-top: $tooltip-arrow-size solid transparent;
          border-bottom: $tooltip-arrow-size solid transparent;
          border-left: $tooltip-arrow-size solid $primary;
        } @else if ($direction == 'is-right') {
          top: 50%;
          right: 100%;
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
          border-top: $tooltip-arrow-size solid transparent;
          border-right: $tooltip-arrow-size solid $primary;
          border-bottom: $tooltip-arrow-size solid transparent;
        }
      }

      @each $name, $pair in $tooltip-colors {
        $color: nth($pair, 1);
        &.is-#{$name} {
          .tooltip-content::before {
            @include tooltip-arrow-color($direction, $color);
          }

          // If light and dark colors are provided
          @if length($pair) >= 4 {
            $color-light: nth($pair, 3);
            &.is-light {
              .tooltip-content::before {
                @include tooltip-arrow-color($direction, $color-light);
              }
            }
          }
        }
      }
    }
  }

  // Base
  .tooltip {
    position: relative;
    display: inline-flex;

    @include tooltip('is-top');
    @include tooltip('is-right');
    @include tooltip('is-bottom');
    @include tooltip('is-left');

    .tooltip-content {
      width: auto;
      border-radius: var(--radius-xs);
      box-shadow: 0 1px 2px 1px rgba($copy-100, 0.2);
      z-index: 38;
      position: absolute;
      pointer-events: none;
    }

    .tooltip-content::before {
      position: absolute;
      content: '';
      pointer-events: none;
      z-index: 38;
    }

    // this is here so we can apply an overflow: hidden; without losing the arrow.
    .tooltip-content-inner {
      @include Subhead2-default;
      padding: 4px 10px;
      white-space: nowrap;
      overflow: hidden;
    }

    .tooltip-trigger {
      width: 100%;
    }

    // Modifiers
    @each $name, $pair in $tooltip-colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      &.is-#{$name} {
        .tooltip-content {
          background: $color;
          color: $color-invert;
        }

        // If light and dark colors are provided
        @if length($pair) >= 4 {
          $color-light: nth($pair, 3);
          $color-dark: nth($pair, 4);
          &.is-light {
            .tooltip-content {
              background: $color-light;
              color: $color-dark;
            }
          }
        }
      }
    }

    &.is-always {
      .tooltip-content::before,
      .tooltip-content {
        opacity: 1;
        visibility: visible;
      }
    }

    &.is-multiline {
      .tooltip-content-inner {
        display: flex;
        flex-direction: column;
        white-space: normal;
      }

      @each $name, $size in $tooltip-multiline-sizes {
        &.is-#{$name} {
          .tooltip-content {
            width: max-content;
            max-width: $size;
          }
        }
      }
    }

    &.has-fixed-width {
      @each $name, $size in $tooltip-multiline-sizes {
        &.is-#{$name} {
          .tooltip-content {
            width: $size;
          }
        }
      }
    }

    &.is-dashed {
      .tooltip-trigger {
        text-decoration: dashed underline $copy-30;
        cursor: default;
      }
    }

    &.is-square {
      .tooltip-content {
        border-radius: 0;
      }
    }
  }
</style>

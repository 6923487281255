import { useOrgStorage } from '@App/scopedStorage';
import { Temporal } from '@js-temporal/polyfill';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import { plainDateNow } from '@/shared/DateTime/helpers';

interface ExpirableValue<T> {
  value: T;
  expiresAt: Temporal.Instant;
}

type DefaultDispatchDate = ExpirableValue<Temporal.PlainDate>;

/**
 * Behaviour to get and store the default dispatch date for outbound consignments for the current user.
 */
export const useDefaultDispatchDate = () => {
  const userStorage = useOrgStorage();
  const defaultDispatchDateStorageKey = 'defaultDispatchDate';

  return {
    /**
     * Record dispatch date preference for the same day it was saved.
     * Passing undefined clears the saved value.
     */
    setTodaysDefaultDispatchDate: (dispatchDate?: ConsignmentFormData['dispatchDate']) => {
      if (!dispatchDate) userStorage.removeItem(defaultDispatchDateStorageKey);
      else {
        // Get the current date at midnight (start of the day)
        const todayAtMidnight = Temporal.PlainDateTime.from(Temporal.Now.plainDate('iso8601')).with({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        // Calculate the end of the day
        const endOfDay = todayAtMidnight.add({ days: 1 }).toZonedDateTime('UTC');

        userStorage.setItem<DefaultDispatchDate>(defaultDispatchDateStorageKey, {
          value: dispatchDate,
          expiresAt: endOfDay.toInstant(),
        });
      }
    },

    /**
     * Retrieve the default dispatch date for the consignment form.
     * The default dispatch date is valid only for the same day it was saved.
     * If no date is found or the value has expired, today's date will be used as the default.
     */
    getTodaysDispatchDate: () => {
      const storedDispatchDate = userStorage.getItem<DefaultDispatchDate>(defaultDispatchDateStorageKey);
      const defaultFallback = plainDateNow();

      // No stored date, default to today.
      if (!storedDispatchDate) {
        return defaultFallback; // Key not found
      }

      try {
        const { value, expiresAt } = storedDispatchDate;

        // Check if the current time is past the expiry time
        if (Temporal.Instant.from(expiresAt).epochMilliseconds <= Temporal.Now.instant().epochMilliseconds) {
          userStorage.removeItem(defaultDispatchDateStorageKey); // Remove expired data
          return defaultFallback;
        }

        return Temporal.PlainDate.from(value);
      } catch (error) {
        logger.error('Failed to parse stored dispatch date:', error);
        return defaultFallback;
      }
    },
  };
};

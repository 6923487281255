import { PrintClientDetails, PrintClientStatus, PrintConfigFormData, Printer } from '@/shared/models';
import ApiClient, { ApiClientError } from '@/shared/services/api-client';
import { OperationResponse } from '@/shared/services/helpers';
import { operations } from '@/shared/services/schema/geppetto-sender-app/autoprint.schema';
import * as mappers from '@/shared/services/sender/mappers';
import { AutoprintConfig } from '@/shared/services/sender/models/AutoprintConfig';

export default class AutoprintClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async fetchConfig(): Promise<AutoprintConfig> {
    type GetConfigResponse = operations['getConfig']['responses']['200']['content']['application/json'];
    const response = await this.apiClient.get<GetConfigResponse>('v0/autoprint/config');

    return mappers.autoprint.mapClientGetPrintConfigToPrintConfig(response.data);
  }

  async updateConfig(printConfig: PrintConfigFormData): Promise<void> {
    type GetPrintersRequest = operations['updateConfig']['requestBody']['content']['application/json'];
    const payload: GetPrintersRequest = {
      // todo move to mapper
      data: {
        enabled: printConfig.enabled,
        defaultLabelPrinter: {
          enabled: printConfig.defaultLabelPrinter.enabled,
          id: printConfig.defaultLabelPrinter.id ? parseInt(printConfig.defaultLabelPrinter.id, 10) : undefined,
        },
        defaultConnotePrinter: {
          enabled: printConfig.defaultConnotePrinter.enabled,
          id: printConfig.defaultConnotePrinter.id ? parseInt(printConfig.defaultConnotePrinter.id, 10) : undefined,
        },
      },
    };

    type GetPrintersResponse = operations['updateConfig']['responses']['202'];
    await this.apiClient.put<GetPrintersResponse>('v0/autoprint/config', payload);
  }

  async fetchClientDetails(): Promise<PrintClientDetails> {
    try {
      const response =
        await this.apiClient.get<OperationResponse<operations['getStatus']>>('v0/autoprint/client/status');

      if (response.status === 204 || !response.data) {
        return { status: PrintClientStatus.notInstalled };
      }

      return mappers.autoprint.mapClientGetPrintClientStatusToPrintClientDetails(response.data);
    } catch (error) {
      if (error instanceof ApiClientError) {
        // TODO(GEPPES-4134) temporarily keeping this until backend changes are released
        if (error.status === 404) {
          // no token or configuration detected
          return { status: PrintClientStatus.notInstalled };
        }

        logger.error('Unable to fetch autoprint status', { cause: error });
        return { status: PrintClientStatus.unavailable };
      }

      throw error;
    }
  }

  async fetchPrinters(): Promise<Printer[]> {
    const response =
      await this.apiClient.get<OperationResponse<operations['getPrinters']>>('v0/autoprint/client/printers');

    if (response.status === 204 || !response.data) {
      logger.error('Autoprint is not installed. Printers cannot be fetch.');
      return [];
    }

    return response.data.data.printers;
  }

  async createSecretKey(): Promise<string> {
    type CreatePrintSecretKeyResponse =
      operations['createSecretKey']['responses']['200']['content']['application/json'];
    const response = await this.apiClient.post<CreatePrintSecretKeyResponse>('v0/autoprint/client/secret-key', {});

    return response.data.data.secretKey;
  }

  async deleteSecretKey(): Promise<boolean> {
    type OperationResult = operations['deleteSecretKey']['responses']['200']['content'];
    await this.apiClient.delete<OperationResult>('v0/autoprint/client/secret-key');
    return true;
  }
}

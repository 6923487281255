import { PrintClientDetails, PrintClientStatus } from '@/shared/models';
import { operations } from '@/shared/services/schema/geppetto-sender-app/autoprint.schema';
import { AutoprintConfig } from '@/shared/services/sender/models/AutoprintConfig';

type ClientPrintConfig = operations['getConfig']['responses']['200']['content']['application/json'];
export const mapClientGetPrintConfigToPrintConfig = ({ data }: ClientPrintConfig): AutoprintConfig => ({
  enabled: data.enabled || false,
  defaultConnotePrinter: {
    enabled: data.defaultConnotePrinter.enabled,
    id: data.defaultConnotePrinter.id ? data.defaultConnotePrinter.id.toString() : undefined,
    name: data.defaultConnotePrinter.name,
  },
  defaultLabelPrinter: {
    enabled: data.defaultLabelPrinter.enabled,
    id: data.defaultLabelPrinter.id ? data.defaultLabelPrinter.id.toString() : undefined,
    name: data.defaultLabelPrinter.name,
  },
});

type ClientPrintStatus = operations['getStatus']['responses']['200']['content']['application/json'];
export const mapClientGetPrintClientStatusToPrintClientDetails = ({ data }: ClientPrintStatus): PrintClientDetails => {
  let status;
  switch (data?.status) {
    case 'running':
      status = PrintClientStatus.running;
      break;
    case 'stopped':
      status = PrintClientStatus.stopped;
      break;
    case 'noActiveClient':
      status = PrintClientStatus.noActiveClient;
      break;
    default:
      status = PrintClientStatus.notInstalled;
      break;
  }

  return {
    status,
    machineName: data.machineName,
    version: data.version,
  };
};

<template>
  <div v-sensitive class="base-tag" :class="[`is-${color}`, `is-${size}`]">
    <component :is="icon" v-if="icon" />
    <slot>{{ label }}</slot>
  </div>
</template>

<script setup lang="ts">
  import type { Component } from 'vue';

  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  export type BaseTagProps = {
    label?: string;
    color?: 'success' | 'warning' | 'danger' | 'info' | 'chrome';
    icon?: Component;
    size?: 'compact' | 'medium';
    sensitive?: boolean;
  };

  const props = withDefaults(defineProps<BaseTagProps>(), {
    label: undefined,
    color: 'chrome',
    icon: undefined,
    size: 'medium',
  });

  const vSensitive = createSensitiveDirective(() => props.sensitive);
</script>

<style scoped lang="scss">
  .base-tag {
    @include H4-default;
    display: inline-block;
    padding: 0 7px;
    margin-bottom: 4px;
    border-radius: var(--radius-xs);
    font-weight: $font-weight-regular;

    :deep(svg) {
      width: 16px;
      height: 16px;
    }

    &.is-compact {
      padding: 2px 8px;
      font-size: 0.8rem;

      :deep(svg) {
        width: 12px;
        height: 12px;
      }

      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    &.is-medium {
      padding: 4px 8px;
      font-size: 0.9rem;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .is-chrome {
    background-color: $chrome-30;
    color: $copy-90;
  }

  .is-success {
    background-color: $success-10;
    color: $success-110;
  }

  .is-warning {
    background-color: $warning-10;
    color: $warning-110;
  }

  .is-danger {
    background-color: $danger-10;
    color: $danger-110;
  }

  .is-info {
    background-color: $info-10;
    color: $info-110;
  }
</style>

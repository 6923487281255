import { LogsEvent, LogsEventDomainContext } from '@datadog/browser-logs';

/**
 * Return true on network requests that are unsuccessful for known reasons, ie if the
 * request was intentionally aborted. This will help us prevent them from being logged.
 * @return void|true
 */
const expectedNetworkError = (event: LogsEvent, context: LogsEventDomainContext): boolean => {
  // aborted requests should not be logged as errors
  // unfortunately this also filters out requests that failed on the network, because
  // the LogsEvent provided by beforeSend doesn't have any detail about whether an xhr
  // was aborted or not.
  // @see https://github.com/DataDog/browser-sdk/issues/362#issuecomment-1683460565
  if (event.error && event.http?.status_code === 0) {
    return true;
  }

  // as above, DataDog now includes an 'isAborted' flag in the error context:
  // https://github.com/DataDog/browser-sdk/issues/362#issuecomment-2032018587
  if ('isAborted' in context && context.isAborted) {
    return true;
  }

  return false;
};

export default expectedNetworkError;

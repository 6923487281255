import Address, { ADDRESS_FROM, createAddress } from '@/shared/models/Address';
import { AddresseeFormData } from '@/shared/models/AddresseeFormData';
import MappingError from '@/shared/services/errors/MappingError';

interface Location {
  name: string;
  addressId?: string;
  address?: Address;
  line2?: string;
  residential: boolean;
}

const newLocation = (): Location => ({
  name: '',
  addressId: '',
  address: createAddress(),
  residential: false,
});

export const createLocation = (
  {
    name,
    addressId,
    address,
    line2,
    residential,
  }: (Omit<Location, 'residential'> & { residential?: boolean }) | undefined = newLocation(),
): Location => ({
  name,
  addressId,
  address: createAddress(address || undefined),
  line2,
  residential: residential ?? false,
});

interface AddressAndLocation extends Address {
  name: string;
  residential: boolean;
}

export const locationFromAddressObject = ({
  name,
  id,
  line1,
  line2,
  locality,
  postcode,
  subdivision,
  countryId,
  timeZone,
  from,
  gnafId,
  residential,
}: AddressAndLocation) =>
  createLocation({
    name,
    addressId: id,
    line2,
    address: createAddress({
      id,
      line1,
      line2,
      locality,
      postcode,
      subdivision,
      countryId,
      timeZone,
      from,
      gnafId,
    }),
    residential,
  });

export const locationFactory = {
  createFromAddresseeFormData(a: AddresseeFormData): Location {
    if (!a.location.address?.timeZone) throw new MappingError('Location time zone is required');
    if (!a.location.address?.line1) throw new MappingError('Location line1 is required');
    if (!a.location.address?.locality) throw new MappingError('Location locality is required');
    if (!a.location.address?.postcode) throw new MappingError('Location postcode is required');
    if (!a.location.address?.subdivision) throw new MappingError('Location subdivision is required');
    if (!a.location.address?.countryId) throw new MappingError('Location countryId is required');

    let from;
    if (a.location.address?.from) {
      from = a.location.address.from === 'suggestion' ? ADDRESS_FROM.suggestion : ADDRESS_FROM.estimate;
    }

    return {
      name: a.location.name || '',
      addressId: a.location.address.id,
      // Unfortunately we need consolidate line2 from two different places:
      // - Get pickup will return line2 in the address
      // - Get consignment will return line2 in the location
      line2: a.location.address?.line2 || a.location.line2,
      residential: a.location.residential,
      address: {
        id: a.location.address.id,
        timeZone: a.location.address.timeZone,
        from,
        gnafId: a.location.address.gnafId,
        line1: a.location.address.line1,
        line2: a.location.address.line2,
        locality: a.location.address.locality,
        postcode: a.location.address.postcode,
        subdivision: a.location.address.subdivision,
        countryId: a.location.address.countryId,
      },
    };
  },
};

export type LocationType = 'locality' | 'address';

export default Location;

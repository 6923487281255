import { APIClientConfig } from '@/shared/services/api-client/api-client';
import { ValidationError } from '@/shared/services/api-client';
import instrumentation from '@/shared/instrumentation';
import { BackendValidationInstrumentationPayload } from '@App/instrumentation/apiClient';
import appInstrumentationTypes from '@App/instrumentation/types';

export const onError: APIClientConfig['onError'] = error => {
  if (error instanceof ValidationError) {
    instrumentation.event<BackendValidationInstrumentationPayload>(appInstrumentationTypes.BACKEND_VALIDATIONS, {
      error,
    });
  }
};

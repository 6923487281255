import { OrderSummary } from '@/shared/models';

import { operations } from '@/shared/services/schema/geppetto-sender-app/orders.schema';

import mapPaginatedResponse from '../../api-client/helpers/mapPaginatedResponse';

type SearchOrdersResponse = operations['getOrders']['responses']['200']['content']['application/json'];

export const mapOrdersSearchResults = (response: SearchOrdersResponse) =>
  mapPaginatedResponse((order: OrderSummary) => order, response);

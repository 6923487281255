<script lang="ts" setup>
  import { useEventListener } from '@vueuse/core';
  import { MaybeRefOrGetter, toValue } from 'vue';
  import type { Component } from 'vue';

  import { THEME as DIALOG_TYPE, Theme } from '@/shared/consts';
  import { useModal } from '@/shared/modals/modalManager';
  import DialogContent from '@/shared/notify/components/DialogContent.vue';
  import vOnOutside from '@/shared/on-outside';

  const modal = useModal();

  const emit = defineEmits(['confirm', 'cancel']);

  const props = withDefaults(
    defineProps<{
      quickClose?: boolean;
      title?: string;
      message?: string | Component;
      type?: Theme;
      confirmLabel?: string;
      confirmLoading?: MaybeRefOrGetter<boolean>;
      cancelLabel?: string | false;
    }>(),
    {
      quickClose: false,
      title: '',
      message: '',
      type: DIALOG_TYPE.PRIMARY,
      confirmLabel: 'Confirm',
      confirmLoading: false,
      cancelLabel: 'Cancel',
    },
  );

  const quickCloseModal = () => props.quickClose && modal.isActive && modal.close();

  useEventListener('keyup', e => {
    if (props.quickClose) {
      if (e.code === 'Escape') quickCloseModal();
    }
  });
</script>

<template>
  <div class="modal-background"></div>
  <div v-on-outside:click="quickCloseModal" class="modal-card modal-dialog">
    <section class="modal-card-body">
      <div v-if="title" v-sensitive class="modal-card-title">{{ title }}</div>
      <template v-if="message">
        <DialogContent v-if="typeof message === 'string'" :text="message" :type="type" />
        <DialogContent v-else :type="type">
          <component :is="message" />
        </DialogContent>
      </template>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button
        v-if="cancelLabel"
        type="button"
        class="button is-secondary"
        data-testref="modal-dialog-cancel"
        @click="emit('cancel')"
      >
        {{ cancelLabel }}
      </button>
      <button
        type="button"
        class="button"
        data-testref="modal-dialog-confirm"
        :class="{
          [`is-${type}`]: true,
          'is-loading': toValue(confirmLoading),
        }"
        @click="emit('confirm')"
      >
        {{ confirmLabel }}
      </button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
  .modal-card-body {
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-top-left-radius: var(--radius-s);
    border-top-right-radius: var(--radius-s);
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .modal-card-foot {
    &:empty {
      display: none;
    }
  }

  :deep(.dialog-container) {
    padding: 0;
  }

  .modal-card {
    max-width: 560px;
  }
</style>

import { toISODate } from '@/shared/DateTime/formatters';
import APIClient from '@/shared/services/api-client';
import { mapClientPickupServiceToPickupService } from '@/shared/services/duke/mappers';
import { PickupServiceDetails, PickupServiceFilter } from '@/shared/services/duke/types/PickupService';
import { operations } from '@/shared/services/schema/geppetto-duke/pickup-service.schema';

export default class PickupServiceClient {
  private _api!: APIClient;

  constructor(baseUrl: string) {
    this._api = new APIClient(baseUrl);
  }

  /**
   * fetch pickup service details. If undefined is returned, the service does not have an integration configured
   */
  async getPickupService(serviceFilter: PickupServiceFilter): Promise<PickupServiceDetails | undefined> {
    type PickupServiceResponse =
      | operations['getPickupService']['responses']['200']['content']['application/json']
      | operations['getPickupService']['responses']['204']['content'];
    const response = await this._api.post<PickupServiceResponse>('/v0/pickup-service', {
      data: {
        agreedServiceId: serviceFilter.agreedServiceId,
        pickupDate: toISODate(serviceFilter.pickupDate),
        from: {
          name: serviceFilter.from.locality, // GEPPES-2737 'name' is actually 'locality'
          postcode: serviceFilter.from.postcode,
          subdivision: serviceFilter.from.subdivision,
          country: serviceFilter.from.countryId,
        },
      },
    });

    if (response.status === 204 || !response.data?.data) {
      return undefined;
    }

    return mapClientPickupServiceToPickupService(response.data.data);
  }
}

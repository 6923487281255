/**
 * ManifestResultSet model
 * represents API result set when searching/listing manifests
 */

import { CONSIGNMENT_TYPES } from '@/shared/models/Consignment/Consignment';
import manifestSummaryFactory, { type ManifestSummary } from '@/shared/models/ManifestSummary';

export interface ManifestResultSet {
  manifests: ManifestSummary[];
  total: number;
  numPages: number;
}

const create = (
  { manifests = [], total = 0, numPages = -1 }: ManifestResultSet = { manifests: [], total: 0, numPages: -1 },
) => ({
  manifests: manifests.map(manifestSummaryFactory.create),
  total,
  numPages,
});

const manifestResultSetFactory = {
  create,
};

export default manifestResultSetFactory;

export const ConsignmentFilterTypes = {
  All: 'all',
  Outbound: CONSIGNMENT_TYPES.OUTBOUND,
  Inbound: CONSIGNMENT_TYPES.INBOUND,
  Transfer: CONSIGNMENT_TYPES.TRANSFER,
};

import { AddressBookEntry, AddressBookSuggestion, ContactSuggestion, ListingFilter } from '@/shared/models';
import { NewAddressBookEntry } from '@/shared/models/AddressBookEntry';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import APIClient, {
  ApiClientRequestConfig,
  GeppettoApiPaginatedResponse,
  GeppettoApiResponse,
} from '@/shared/services/api-client';
import { formatSearchParams } from '@/shared/services/helpers';

import { operations } from '@/shared/services/schema/geppetto-address-book/entries.schema';

import mappers, { type AddressBookSuggestionApiData, type ContactSuggestionApiData } from './mappers';

export interface AddressBookClientConfig {
  resourcesPerPage: number;
}

type AddressBookEntryDuplicateCheck = Pick<AddressBookEntry, 'siteId' | 'location'>;
type GetEntryClientResponse = operations['getAddressBookEntry']['responses']['200']['content']['application/json'];
type SaveEntryClientResponse = operations['createAddressBookEntry']['responses']['201']['content']['application/json'];
type UpdateEntryClientResponse =
  operations['updateAddressBookEntry']['responses']['200']['content']['application/json'];

export default class AddressBookClient {
  private apiClient: APIClient;

  private config: AddressBookClientConfig;

  constructor(apiClient: APIClient, config: AddressBookClientConfig) {
    this.apiClient = apiClient;
    this.config = config;
  }

  async checkDuplicateEntries({
    entry,
  }: {
    entry: AddressBookEntryDuplicateCheck;
  }): Promise<PaginatedItems<AddressBookSuggestion>> {
    const response = await this.apiClient.post<GeppettoApiPaginatedResponse<AddressBookSuggestionApiData>>(
      'entries/duplicates',
      mappers.AddressBookDuplicates.toAPI(entry),
    );

    const duplicates = mappers.AddressBookSuggestions.fromAPI(response);

    logger.debug('[AddressBookService] AddressBookEntry duplicate check', {}, { response });

    return duplicates;
  }

  async listEntries(
    {
      highlight = { start: '{{', stop: '}}' },
      limit = this.config.resourcesPerPage,
      offset = 0,
      sort = '',
      search = '',
      filters = {},
    }: ListingFilter,
    config?: ApiClientRequestConfig,
  ): Promise<PaginatedItems<AddressBookSuggestion>> {
    const params = formatSearchParams({
      limit,
      offset,
      sort,
      filters,
      search,
      highlight,
    }) as { [key: string]: string };
    const response = await this.apiClient.query<GeppettoApiPaginatedResponse<AddressBookSuggestionApiData>>(
      '/entries',
      { ...config, params },
    );

    const entries = mappers.AddressBookSuggestions.fromAPI(response);

    logger.debug('[AddressBookService] Entry list', {}, { response });

    return entries;
  }

  async getAddressBookEntry({ entryId }: { entryId: string }): Promise<AddressBookEntry> {
    const response = await this.apiClient.get<GetEntryClientResponse>(`entries/${entryId}`);

    const entry = mappers.AddressBookEntry.fromAPI(response.data);

    logger.debug('[AddressBookService] AddressBookEntry', {}, { response });

    return entry;
  }

  async saveEntry({ entry }: { entry: NewAddressBookEntry }): Promise<AddressBookEntry> {
    const response = await this.apiClient.post<SaveEntryClientResponse>(
      'entries',
      mappers.AddressBookEntry.toAPI(entry),
    );

    const savedEntry = mappers.AddressBookEntry.fromAPI(response.data);

    // contact suggestions are not returned from the API,
    // so preserve them on the new model
    savedEntry.contactSuggestions = entry.contactSuggestions;

    logger.debug('[AddressBookService] AddressBookEntry save', {}, { response });

    return savedEntry;
  }

  async updateEntry({ entry }: { entry: AddressBookEntry }): Promise<AddressBookEntry> {
    const response = await this.apiClient.put<UpdateEntryClientResponse>(
      `entries/${entry.id}`,
      mappers.AddressBookEntry.toAPI(entry),
    );

    const updatedEntry = mappers.AddressBookEntry.fromAPI(response.data);

    // contact suggestions are not returned from the API,
    // so preserve them on the new model
    updatedEntry.contactSuggestions = entry.contactSuggestions;

    logger.debug('[AddressBookService] AddressBookEntry update', {}, { response });

    return updatedEntry;
  }

  async deleteEntry({ entry }: { entry: Pick<AddressBookEntry, 'id'> }): Promise<void> {
    const response = await this.apiClient.delete(`entries/${entry.id}`);

    logger.debug('[AddressBookService] AddressBookEntry delete', {}, { response });
  }

  async saveContact({
    entry,
    contactSuggestion,
  }: {
    entry: Pick<AddressBookEntry, 'id'>;
    contactSuggestion: ContactSuggestion;
  }): Promise<ContactSuggestion> {
    const response = await this.apiClient.post<GeppettoApiResponse<ContactSuggestionApiData>>(
      `entries/${entry.id}/contacts`,
      mappers.ContactSuggestion.toAPI(contactSuggestion),
    );

    const savedContact = mappers.ContactSuggestion.fromAPI(response.data);

    logger.debug('[AddressBookService] Contact save', {}, { response });

    return savedContact;
  }

  async updateContact({
    entry,
    contactSuggestion,
  }: {
    entry: Pick<AddressBookEntry, 'id'>;
    contactSuggestion: ContactSuggestion;
  }): Promise<ContactSuggestion> {
    const response = await this.apiClient.put<GeppettoApiResponse<ContactSuggestionApiData>>(
      `entries/${entry.id}/contacts/${contactSuggestion.contact.id}`,
      mappers.ContactSuggestion.toAPI(contactSuggestion),
    );

    const updatedContact = mappers.ContactSuggestion.fromAPI(response.data);

    logger.debug('[AddressBookService] Contact update', {}, { response });

    return updatedContact;
  }

  async deleteContact({
    entry,
    contactSuggestion,
  }: {
    entry: AddressBookEntry;
    contactSuggestion: ContactSuggestion;
  }): Promise<void> {
    const response = await this.apiClient.delete(`entries/${entry.id}/contacts/${contactSuggestion.contact.id}`);

    logger.debug('[AddressBookService] Contact delete', {}, { response });
  }
}

import { MovementFlowType } from '@/shared/models';

import {
  CustomFieldsSchemaResponse,
  mapCustomFieldsSchemaResponseToSchema,
} from '@/shared/services/customFields/mappers/customFieldsSchemaMappers';

import ApiClient from '../api-client';

export interface GetSchemaParams {
  siteId: UUID;
  movementFlow: MovementFlowType;
  entityType: 'consignment'; // | 'estimate' | 'pickup'
}

export default class CustomFieldsClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  public async getSchema(params: GetSchemaParams) {
    const response = await this.apiClient.get<CustomFieldsSchemaResponse>(`/custom-fields/${params.entityType}`, {
      params: {
        siteId: params.siteId,
        type: params.movementFlow,
      },
    });

    logger.debug('[CustomFieldsClient] get schema success', {}, { params, response });
    return mapCustomFieldsSchemaResponseToSchema(response.data);
  }
}
